import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { IoIosArrowForward } from "react-icons/io";
import Star from "../images/star.svg";

const TrustPilotReviews = ({
	tagline,
	title,
	heading,
	logo,
	reviews,
	btnCta1,
}) => {
	const [reviewQuotes, setReviewQuotes] = useState([]);
	const [sortedReviews, setSortedReviews] = useState([]);

	useEffect(() => {
		if (reviews && reviews.nodes.length > 0) {
			// Sort the reviews by date in descending order
			const sorted = [...reviews.nodes].sort(
				(a, b) => new Date(b.date) - new Date(a.date)
			);
			setSortedReviews(sorted);

			const quotes = sorted.map((item) => {
				const tempDiv = document.createElement("div");
				tempDiv.innerHTML = item.reviewPostFields.reviewQuote;
				const firstParagraph = tempDiv.querySelector("p");
				return firstParagraph ? firstParagraph.outerHTML : "";
			});
			setReviewQuotes(quotes);
		}
	}, [reviews]);

	return (
		<section className="my-3 my-md-6 pl-72">
			<Container>
				<Row className="justify-content-center text-center">
					<Col lg={6}>
						<span className="text-primary">{tagline}</span>
						<h2 className="text-light-black">{title}</h2>
					</Col>
				</Row>
				<Row className="mt-5 d-md-none">
					<Col
						md={6}
						lg={4}
						xl={3}
						className="py-3 text-start d-flex justify-content-center"
					>
						<div className="text-center card-shadow w-100" style={cardWrapper}>
							<GatsbyImage
								image={logo.node?.localFile.childImageSharp.gatsbyImageData}
								alt={logo.node?.altText}
								width={180}
							/>
							<h3 className="text-green">{heading}</h3>
						</div>
					</Col>
					{reviewQuotes.length > 0 && (
						<>
							{sortedReviews.slice(0, 3).map((item, i) => (
								<Col md={6} lg={4} xl={3} className="reviews-item py-3" key={i}>
									<div className="card-shadow" style={cardWrapper}>
										<div className="d-flex justify-content-between">
											<div className="d-flex align-items-center">
												{Array.from(
													{ length: item.reviewPostFields.reviewStarSelect[0] },
													() => (
														<Star />
													)
												)}
											</div>
											<span
												className="montserrat-light fw-light"
												style={{ fontSize: "12px" }}
											>
												{item.date}
											</span>
										</div>
										<h6 className="pt-3 pb-2 mb-0">{item.title}</h6>
										<div
											dangerouslySetInnerHTML={{
												__html: reviewQuotes[i],
											}}
											className="fw-light content-wrapper"
										></div>
										<hr
											className="bg-schema-grey my-2"
											style={{ width: "60px", height: "1px" }}
										/>
										<span className="fw-bold montserrat-bold">
											{item.reviewPostFields.reviewAuthor}
										</span>
									</div>
								</Col>
							))}
						</>
					)}
				</Row>
				<Row className="mt-5 d-none d-md-flex">
					<Col
						md={6}
						lg={4}
						xl={3}
						className="py-3 text-start d-flex justify-content-center"
					>
						<div className="text-center card-shadow w-100" style={cardWrapper}>
							<GatsbyImage
								image={logo.node?.localFile.childImageSharp.gatsbyImageData}
								alt={logo.node?.altText}
								width={180}
							/>
							<h3 className="text-green">{heading}</h3>
						</div>
					</Col>
					{reviewQuotes.length > 0 && (
						<>
							{sortedReviews.map((item, i) => (
								<Col md={6} lg={4} xl={3} className="reviews-item py-3" key={i}>
									<div className="card-shadow" style={cardWrapper}>
										<div className="d-flex justify-content-between">
											<div className="d-flex align-items-center">
												{Array.from(
													{ length: item.reviewPostFields.reviewStarSelect[0] },
													() => (
														<Star />
													)
												)}
											</div>
											<span
												className="montserrat-light fw-light"
												style={{ fontSize: "12px" }}
											>
												{item.date}
											</span>
										</div>
										<h6 className="pt-3 pb-2 mb-0">{item.title}</h6>
										<div
											dangerouslySetInnerHTML={{
												__html: reviewQuotes[i],
											}}
											className="fw-light content-wrapper"
										></div>
										<hr
											className="bg-schema-grey my-2"
											style={{ width: "60px", height: "1px" }}
										/>
										<span className="fw-bold montserrat-bold">
											{item.reviewPostFields.reviewAuthor}
										</span>
									</div>
								</Col>
							))}
						</>
					)}
				</Row>
				{btnCta1 && btnCta1.url && (
					<Button
						as={Link}
						href={btnCta1.url}
						size="lg"
						className="px-4 py-3 mt-2 montserrat-bold fs-6 bg-transparent border-0 text-primary d-flex mx-auto"
						target={btnCta1.target}
						style={{ width: "fit-content" }}
					>
						{btnCta1.title}
						<IoIosArrowForward className="fs-4 ms-2" />
					</Button>
				)}
			</Container>
		</section>
	);
};

export const cardWrapper = {
	height: "232px",
	padding: "24px 20px",
	borderRadius: "12px",
};

export default TrustPilotReviews;
